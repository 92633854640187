import "./src/styles/global.scss"
import canvasMemoryReset from "./src/utils/canvas-memory-reset"

export const onRouteUpdate = ({ location, prevLocation }) => {
  // SP Menu展開時のiOS対策
  const body = document.querySelector('body');
  if (body.classList.contains('spMenuOpen') ) {
    body.classList.remove('spMenuOpen');
  } else {
    const spMenuTrigger = document.getElementById('spMenuTrigger');
    spMenuTrigger.addEventListener('click', () => {
      const spMenu = document.getElementById('spMenu');
      const body = document.querySelector('body');
      let spMenuClose = spMenu.getAttribute('aria-hidden');
      if ( spMenuClose === "true") {
        body.classList.add('spMenuOpen');
      } else {
        body.classList.remove('spMenuOpen');
      }
    });  
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // ダッシュボードに関するページ遷移時はcanvasのメモリをリセットする
  if (location.pathname.includes('/dashboard/')) {
    canvasMemoryReset(`canvas`)
  }
};

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
};