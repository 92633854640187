/*
 * iOS Safari canvasに描画できるmemory制限対策
 * https://developer.apple.com/forums/thread/687866
 * 
 */
export default function canvasMemoryReset(target) {
  if (typeof document !== 'undefined') {
    const canvases = document.querySelectorAll(target)
    canvases.forEach(canvas => {
      canvas.width  = 1
      canvas.height = 1
      canvas.remove()
      canvas = null
    })  
  }
}